<template>
  <div class="home">
    <h1> 这是一个银行余额计算器 </h1>
    <div>
      <el-row style="display:flex;justify-content:center;padding: 0 20px 30px 30px;">
        <el-col  style="padding:20px ;border:2px solid silver;margin-top:30px;width:500px">
          <el-form :model="ruleForm1" :label-position="labelPosition" :rules="rules" ref="ruleForm1" label-width="150px" class="demo-ruleForm">
            <el-form-item label="每年存入银行金额" prop="every_year">
              <el-input style="width: 200px;"  v-model="ruleForm1.every_year" placeholder="单位：元"></el-input>
            </el-form-item>
            <el-form-item label="银行存款利率" prop="rate">
              <el-input style="width: 200px"  v-model="ruleForm1.rate" placeholder="例：x%"></el-input>
            </el-form-item>
            <el-form-item label="银行存款年份" prop="year">
              <el-input style="width: 200px" v-model="ruleForm1.year" placeholder="单位：年" >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm1')">提交</el-button>
            </el-form-item>
          </el-form>
          <div v-if="result !== null">
            <p>{{ ruleForm1.year }}年后本金为：{{ result }}元</p>
          </div>

          <el-form :model="ruleForm2" ref="ruleForm2" :rules="rules" class="demo-ruleForm">
            <el-form-item label="几年后存款利率" prop="interestRate">
              <el-input v-model="ruleForm2.interestRate" placeholder="例：x%"></el-input>
            </el-form-item>
            <el-form-item label="多少年后开始取款" prop="gapYear">
              <el-input v-model="ruleForm2.gapYear" placeholder="单位：年"></el-input>
            </el-form-item>
            <el-form-item>
            <el-button type="primary" @click="calculateWithdrawals('ruleForm2')">提交</el-button>
            </el-form-item>
          </el-form>

          <div v-if="balance !== null">
            <p>此时本金为：{{balance }}元</p>
          </div>


          <el-form :model="ruleForm3" ref="ruleForm3" :rules="rules" class="demo-ruleForm">
            <el-form-item label="每年取款多少元" prop="annualWithdrawal" >
              <el-input v-model="ruleForm3.annualWithdrawal" placeholder="单位：元"></el-input>
            </el-form-item>
            <el-form-item label="仍会取款几年" prop="withdrawalYears">
              <el-input v-model="ruleForm3.withdrawalYears" placeholder="单位：年"></el-input>
            </el-form-item>
            <el-button type="primary" @click="calculatefinal('ruleForm3')">计算取款后余额</el-button>
            <el-button @click="resetForm('ruleForm1','ruleForm2','ruleForm3')">重置</el-button>
          </el-form>

          <div v-if="finalbalance !== null">
            <p>账户的余额为：{{finalbalance.toFixed(2) }}元</p>
          </div>
<!--          <div v-if="zeroyear !== null">-->
<!--            <p>且取款{{zeroyear }}年后，余额归零。</p>-->
<!--          </div>-->




        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      labelPosition: 'left',
      ruleForm1: {
        every_year: '',
        rate:'',
        year: '',
      },
      ruleForm2:
              {
                interestRate:'',
                gapYear:'',
              },
      ruleForm3:{
        annualWithdrawal:'',
        withdrawalYears:'',
      },
      result: null ,// 存储第一次本金计算结果
      balance:null,//第二次本金计算结果
      finalbalance:null, //最后余额
      zeroyear:null,//余额归零的年数
      rules: {
        every_year: [
          { required: true, message: '请输入每年存入银行金额', trigger: 'blur' },
        ],
        rate:[
          { required:true, message:'请输入银行存款利率',  trigger: 'change'}
        ],
        year: [
          { required: true, message: '请输入银行存款年份 （年）', trigger: 'change' }
        ],
        interestRate:[
          { required: true, message: '请输入存款利率 x%', trigger: 'change'}
        ],
        gapYear:[
          { required: true, message: '请输入用户每年取款多少元', trigger: 'change'}
        ],
        annualWithdrawal:[
          { required: true, message: '请输入用户还会取款几年', trigger: 'change'}
        ],
        withdrawalYears:[
          { required: true, message: '请输入用户还会取款几年', trigger: 'change'}
        ],

      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { every_year, rate, year } = this.ruleForm1;
          // 转换输入为数字
          const everyYearAmount = parseFloat(every_year);
          const interestRate = parseFloat(rate);
          const investmentYears = parseInt(year);

          // 计算本金
          let M = 0;
          for (let item = 1; item <= investmentYears; item++) {
            M += everyYearAmount;
            M *= (1 + interestRate * 0.01);
          }
          // 设置计算结果
          this.result = M.toFixed(2); // 格式化结果为两位小数

          alert('提交成功!');
        } else {
          alert('提交失败!!');
          return false;
        }
      });
    },
    resetForm(form1,form2,form3) {
      this.$refs[form1].resetFields();
      this.$refs[form2].resetFields();
      this.$refs[form3].resetFields();
    },
    calculateWithdrawals(formName2){
      this.$refs[formName2].validate((valid) => {
        if (valid) {
          const { interestRate, gapYear } = this.ruleForm2;
          // 转换输入为数字
          const x = parseFloat(interestRate);
          const gapYears = parseFloat(gapYear);

          let balance1 = this.result;

          //gap_year年后本金为：
          balance1 = balance1 * (1 + x * 0.01 * gapYears); // 计算利息
          console.log("计算后的余额:", balance1); // 调试输出

          // 设置计算结果
          this.balance = balance1.toFixed(2); // 格式化结果为两位小数
          alert('提交成功!');
        } else {
          alert('提交失败!!');
          return false;
    }
      })
    },
    calculatefinal(formName3){
      this.$refs[formName3].validate((valid) => {
        if (valid) {
          const { annualWithdrawal, withdrawalYears } = this.ruleForm3;
          // 转换输入为数字
          const y = parseFloat(annualWithdrawal);
          const z = parseFloat(withdrawalYears);
          let final= this.balance;

          for (let item = 1; item <= z; item++) {
            // 计算存款利息
            final *= (1 + this.ruleForm2.interestRate * 0.01);
            // 进行取款
            final -= y;
          }
          // 设置最终余额
          this.finalbalance = final;

          let flag=this.balance;
          let years = 0;
          while (flag > 0) {
            flag *= (1 + this.ruleForm2.interestRate * 0.01); // 增加利息
            flag -= y;              // 减去取款
            years=years+1;                            // 年数累加
          }
          this.zeroyear=years;
          alert('提交成功！')
        }
        else{
          alert('提交失败!!');
          return false;
        }
        })
    },


  },

}
</script>
