import router from './router'
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';

Vue.use(ElementUI,{size:"small"});



Vue.config.productionTip = false


// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')

new Vue({
  render: h => h(App),
  router,
  components:{
    App  //在 index.html页面不使用这个组件，就会返回 We're sorry but demo3 doesn't work properly without JavaScript enabled. Please enable it to continue.
  }
}).$mount('#app')
